import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';
import {get} from 'lodash';

import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import PageHeader from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import Typography from 'components/Typography';
import ViewDetails from 'components/ViewDetails';
import ClickablePagination from 'components/ClickablePagination';
import Modal from 'components/Modal';

import api from 'api';
import {getFullName} from 'helpers/user';
import {
  ListResponse,
  Customer,
  DataItem,
  SelectOption,
  DataTableSchemaItem,
} from 'types';

import styles from '../Settings.module.scss';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import Button from 'components/Button';
import FormGroup from '../../../components/FormGroup';
import TextField from '../../../components/TextField';
import {validateEmail} from '../../../utils/validators';
import FormActionGroup from '../../../components/FormActionGroup';
import toast from 'react-hot-toast';
import {format, parse} from 'date-fns';
import {useForm} from 'react-hook-form';
import useSort from 'hooks/useSort';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'name',
    header: 'Name',
    sortable: true,
  },

  {
    dataKey: 'phone',
    header: 'Phone',
  },
  {
    dataKey: 'email',
    header: 'Email',
  },
  {
    dataKey: 'total_prescriptions',
    header: 'Total prescriptions',
    align: 'center',
    sortable: true,
  },
  {
    dataKey: 'date',
    header: 'Date',
    sortable: true,
  },
  {
    dataKey: 'view',
    header: '',
  },
];

async function getUsers({queryKey}: QueryFunctionContext<string[]>) {
  const [, keyWord, page, order] = queryKey;
  const {data} = await api.get<ListResponse<Customer>>(`/customers/`, {
    params: {
      order: order,
      limit: 20,
      offset: keyWord ? 0 : +page * 20,
      search: keyWord ? keyWord : undefined,
    },
  });

  return data;
}

function Users() {
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_users`) || ''
  );

  const [isModalOpen, setModalOpen] = useState(
    get(useLocation().state, 'isAdd', false)
  );
  const {sortBy, sortOrder, order, onSort} = useSort('id', 'desc');

  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const {
    data: users,
    isLoading,
    refetch,
  } = useQuery(
    ['users', keyWord, String(currentPage - 1 || 0), order],
    getUsers
  );

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params]);

  const history = useHistory();

  const handleNavigateUser = useCallback(
    (row: DataItem) => {
      history.push(`/settings/users/${row.id}/edit`);
      // history.push(`/settings/users/${row.id}`);
    },
    [history]
  );

  const data = useMemo(
    function () {
      return users
        ? users.results.map((u) => ({
            ...u.user,
            ...u,
            name: getFullName(u.user),
            date: get(u, 'created_at', ''),
            view: <ViewDetails to={`/settings/users/${u.user.id}/edit`} />,
            // view: <ViewDetails to={`/settings/users/${u.user.id}/requests`} />, =>( For now )
          }))
        : [];
    },
    [users]
  );
  const handleOrdersClose = () => {
    setModalOpen(false);
  };

  return (
    <div className={styles.main_container}>
      <PageHeader className={styles.top_header}>
        <Typography variant="h2">Patients</Typography>
        <div className={styles.count_wrapper}>
          <Typography textAlign="right" variant="h4">
            {'Total Patient count:'}
          </Typography>
          <Typography
            textAlign="right"
            className={styles.countText}
            variant="h4"
          >
            {users?.count}
          </Typography>
        </div>
      </PageHeader>
      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput
            value={keyWord}
            size="sm"
            onChange={(e) => {
              setKeyWord(e.target.value);
              sessionStorage.setItem(`search_users`, e.target.value);
            }}
          />
          <Button color="green" size="sm" onClick={() => setModalOpen(true)}>
            Add New Patient
          </Button>
        </ListActionsWrapper>
        <DataTable
          data={data}
          schema={SCHEMA}
          loading={isLoading}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
          onClick={handleNavigateUser}
        />
        <ClickablePagination
          page={currentPage}
          total={Math.round((users?.count || 0) / 20)}
          setCurrentPage={setCurrentPage}
        />
      </ListWrapper>
      <NewCustomer
        onSuccess={() => {
          refetch();
        }}
        isOpen={isModalOpen}
        onClose={handleOrdersClose}
      />
    </div>
  );
}

interface FormValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  birthday: string;
  company: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zipcode: string;
  state: SelectOption;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data: Customer) => void;
};

export const NewCustomer = ({isOpen, onClose, onSuccess}: Props) => {
  const {
    handleSubmit,
    register,
    formState: {errors},
    reset,
  } = useForm<FormValue>();

  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function handleDateChange(event: {target: {value: any}}) {
    const inputDate = event.target.value;
    const formattedDate = formatDateString(inputDate);
    setDate(formattedDate);
  }

  function formatDateString(inputDate: string) {
    const numbersOnly = inputDate.replace(/[^0-9]/g, '');
    const month = numbersOnly.slice(0, 2);
    const day = numbersOnly.slice(2, 4);
    const year = numbersOnly.slice(4, 8);

    let formattedDate = '';
    if (month) {
      formattedDate += month;
      if (day) {
        formattedDate += `/${day}`;
        if (year) {
          formattedDate += `/${year}`;
        }
      }
    }

    if (formattedDate.length > 10) {
      formattedDate = formattedDate.slice(0, 10);
    }

    return formattedDate;
  }

  const submithandler = async (data: FormValue) => {
    setIsLoading(true);
    const user = {
      ...data,
      birthday: format(
        parse(data.birthday, 'MM/dd/yyyy', new Date()),
        'yyyy-MM-dd'
      ),
    };

    try {
      const data = await api.post('/customers/', user);
      onSuccess(data.data);
      setIsLoading(false);
      onClose();
      reset();
      setDate('');
    } catch (error) {
      toast.error(
        get(error, 'response.data.error_message', 'Something went wrong')
      );
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.list_wrapper}>
      <div className={styles.formWrapper}>
        <FormGroup columns={2}>
          <TextField
            label="First name"
            placeholder="First name"
            {...register('first_name', {required: 'Required'})}
            error={errors.first_name?.message}
          />
          <TextField
            label="Last name"
            placeholder="Surname"
            {...register('last_name', {required: 'Required'})}
            error={errors.last_name?.message}
          />
        </FormGroup>

        <FormGroup columns={1}>
          <TextField
            label="Email address"
            placeholder="Email address"
            {...register('email', {
              required: 'Required',
              validate: validateEmail,
            })}
            error={errors.email?.message}
          />
        </FormGroup>

        <FormGroup columns={1}>
          <TextField
            label="Phone number"
            placeholder="Phone number"
            {...register('phone', {
              required: 'Required',
              minLength: {
                value: 10,
                message: 'Phone number length must be 10',
              },
            })}
            maxLength={10}
            error={errors.phone?.message}
          />
        </FormGroup>

        <FormGroup columns={1}>
          <TextField
            label="Date of birth"
            placeholder="mm/dd/yyyy"
            {...register('birthday', {
              required: 'Required',
            })}
            value={date}
            onChange={handleDateChange}
            maxLength={10}
            error={errors.birthday?.message}
          />
        </FormGroup>

        <FormActionGroup className={styles.buttonWrap}>
          <Button onClick={onClose} component="a" color="gray">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(submithandler)}
            type="submit"
            color="green"
            loading={isLoading}
          >
            Save
          </Button>
        </FormActionGroup>
      </div>
    </Modal>
  );
};

export default Users;
